<template>
    <div>
        <v-dialog v-model="dialog" max-width="970" persistent>
            <v-card style="padding:20px">

                <v-card-title class="headline grey lighten-2" primary-title
                    style="	font-family: 'droid_bold' !important;background-color: #eee!important;color:blue;font-size:14px">

                    <span>ميزات اضافيه</span>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog=false;close()">
                        <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>


                <div style="padding-top:9px;padding-bottom:9px">
                    <v-btn dense small color="green" pt-4 pb-4 style="color:#fff"
                        @click="req=true,req_action='add',req_name='',table=false">
                        اضافه ميزه جديد
                    </v-btn>
                </div>

                <v-form ref="valid_ItemFeatures" v-model="valid_ItemFeatures">
                    <div v-if="req">


                        <v-layout row wrap pr-4>
                            <v-flex xs12>
                                <v-text-field background-color="#fff" height="50" placeholder="اسم الميزه"
                                    data-vv-name="name" v-model="editeItemFeatures.feature_name" required>
                                </v-text-field>

                            </v-flex>


                            <v-flex xs12>
                                <v-text-field background-color="#fff" height="50" placeholder="سعر الميزه"
                                    data-vv-name="name" v-model="editeItemFeatures.feature_price" :rules="numberRul"
                                    required>
                                </v-text-field>
                            </v-flex>


                            <v-flex xs3 pr-3 pt-4>


                                <v-bt v-if="req_action=='edite'">
                                    <v-btn @click="req_update()" color="green" pb-4 style="color:#fff">
                                        تعديل
                                    </v-btn>
                                </v-bt>


                                <v-btn v-else @click="req_save()" color="green" pb-4 style="color:#fff">
                                    حفظ
                                </v-btn>


                                <v-btn pr-3 mr-4 color="red" @click="req=false" pb-4
                                    style="color:#fff;margin-right:7px">
                                    الغاء
                                </v-btn>

                            </v-flex>
                        </v-layout>

                    </div>
                </v-form>

                <br>
                <v-simple-table v-if="table">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-right">#</th>
                                <th class="text-right">اسم الميزه</th>
                                <th class="text-right">سعر الميزه</th>
                                <th class="text-right"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in ItemFeatures" :key="item">
                                <td> {{ item.id}} </td>
                                <td>{{item.feature_name}}</td>
                                <td>{{item.feature_price}}</td>

                                <td>



                                    <v-icon small class="mr-2" @click="editItemFeatures(item),req_action='edite'">
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon small @click="DeleteItemFeatures(item)">
                                        mdi-delete
                                    </v-icon>

                                </td>

                            </tr>

                        </tbody>
                    </template>
                </v-simple-table>


            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    EventBus
  } from '../event-bus.js';
    export default {
  props: {

            'item_id':Number,
            'ItemFeatures':Array

        },
        data: () => ({
            dialog: true,
           /// ItemFeatures: [],
            req:false,
            req_action:'',
            table:true,
            editedIndexreq :-1,
            editeItemFeatures: {
                id: '',
                item_id: '',
                feature_name: '',
                feature_price: '',
                updated_at:'',
                created_at:''

            },

            valid_ItemFeatures: false,
        }),

///ItemFeatures/owner/delete/
 mounted() {
     


    },

        methods: {
            close()
            {
                 this.ItemFeatures=[],
        EventBus.$emit('ItemFeature', true)
       

            },
            req_update() {

                if (this.$refs.valid_ItemFeatures.validate()) {

                    var data = {
                        item_id: this.item_id,
                        feature_name: this.editeItemFeatures.feature_name,
                        feature_price:this.editeItemFeatures.feature_price

                    };

                    if (this.editeItemFeatures.id == undefined) {
   
                        // const index = this.editedItem.ItemFeatures.indexOf(item)
                        this.ItemFeatures[this.editedIndexreq] = this.editeItemFeatures;
                        this.req = false,
                         //   this.getitems();

                        this.$swal('   تم', "       تم   تعديل الميزه ", 'success')

                    } else {

                       
                        this.ItemFeatures[this.editedIndexreq]= data;

                       this.$http({
                                method: 'post',
                                url: 'v2/ItemFeatures/owner/update/' +this.editeItemFeatures.id,
                                data: data,
                                headers: {

                                }

                            })
                            .then(response => {
                                response,
                             
                                 //   this.getitems();

                                this.$swal('   تم', "       تم   تعديل الميزه ", 'success')
  
                            this.req=false,
                          this.table=true,

                                this.$refs.valid_ItemFeatures.reset();
                                // this.$refs.valid_ItemFeatures.reset();
                                // if (response.data.success == true) {
                                //     this.$refs.form.reset()
                                //     this.dialog = false;
                                // }

                            })
                            .catch(error => {
                                error


                            }).finally(d => {
                                d
                            });
                    }
                }



            },
            editItemFeatures(item) {
                this.table=false,
               
                this.editedIndexreq = this.ItemFeatures.indexOf(item)
                this.editeItemFeatures = Object.assign({}, item)
                this.req = true;

            },
            req_save() {

                if (this.$refs.valid_ItemFeatures.validate()) {

                    var data = {
                        item_id: this.item_id,
                        feature_name: this.editeItemFeatures.feature_name,
                        feature_price:this.editeItemFeatures.feature_price

                    };
                    this.ItemFeatures.push(data);



                    // if (this.submit_name == 'edite') {


                    //   this.offer_url = '/v2/Offers/update/' + this.offer_info_id;


                    // } else if (this.submit_name == 'add') {
                    //   this.offer_url = '/v2/Offers/store';

                    // }




                    // this.loading = true;
                    this.$http({
                            method: 'post',
                            url: 'v2/ItemFeatures/owner/set',
                            data: data,
                            headers: {

                            }

                        })
                        .then(response => {
                            this.req = false,
                            this.table=true,
                                this.$swal('تم', " تم اضافه ميزه", 'success')
                         //   this.getitems();
                            this.dialog = false,
                            this.req=false,
                                this.dialog = true,

                                this.$refs.valid_ItemFeatures.reset();

                            if (response.data.success == true) {
                                this.$refs.form.reset()
                                this.dialog = false;


                            }
                            //



                        })
                        .catch(error => {
                            error,
                            this.err_show = true;
                            //this.$swal('خطاء', "     لم يتم اضافة الحجز", 'error')

                        }).finally(d => {
                            d,
                            this.offer_dialog = false;
                            this.loading = false;
                        });
                } else {

                    this.err_show = true;




                }

            },

            DeleteItemFeatures(item) {

        this.editedIndexreq = this.ItemFeatures.indexOf(item);
        const Swal = require('sweetalert2')
        Swal.fire({
          title: "هل انت متاكد من حذف الميزه",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'نعم ',
          cancelButtonText: 'خروج'

        }).then((result) => {
          if (result.value) {

          if (item.id == undefined) {
            // const index = this.editedItem.ItemFeatures.indexOf(item)
            this.ItemFeatures.splice(this.editedIndexreq, 1)
            this.item = false,
              this.getitems();

            this.$swal('تم', "تم حذف الميزه", 'success')

          } else {


            this.$http({
                method: 'delete',
                url: '/ItemFeatures/owner/delete/' + item.id,
                headers: {}
              })
              .then(response => {
                this.req = false,
                  this.ItemFeatures.splice(this.editedIndexreq, 1)
                 this.$swal('تم', "تم حذف الميزه", 'success')
                //this.$swal('تم', "تم حذف المرفق", 'success')
                this.$refs.valid_ItemsReservationRequirements.reset();
                if (response.data.success == true) {
                  this.$refs.form.reset()
                  this.dialog = false;
                }

              })
              .catch(error => {
                error


              }).finally(d => {
                d
              })
          }

        }

            }
        
        );




      },

            
        }

    }
</script>