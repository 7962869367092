<template>
  <v-layout row wrap pr-3 mr-2 mt-1>


    <v-data-table :headers="headers" :items="items" :server-items-length="15" sort-by="calories"
      class="elevation-1 request_table" hide-default-footer style="width:96%" :loading="loading"
      loading-text="جاري تحميل البيانات" color="red">

      <template v-slot:top>
        <v-toolbar flat color="white" style="" class="toolbarrs">

          <v-layout row wrap>
            <v-flex xs12 md2 sm2>
              <p>الخدمات </p>


              <!-- <v-divider class="mx-4" inset vertical></v-divider>
             -->


            </v-flex>


            <v-flex xs12 md6 sm6 pt-2 style="float:right">
              <v-alert v-if="item_status==24" dense type="success">
                شكراً تم اضافة الحجز سيتم نشر الحجز في المنصه بعد تفعيل الحساب من قبل الاداره
              </v-alert>

              <div v-else-if="owner.OwnerSubscriptions!==null">
                <v-alert style="    float: right;"
                  v-if="owner.OwnerSubscriptions.remaining_reservations_number==0 || owner.OwnerSubscriptions.status_id==30"
                  dense type="error">
                  حزمه الاشتراك الحاليه منتيه الصلاحيه يرجئ تفعيل حزمه اشتراك جديد
                </v-alert>


                <v-alert style="float: right;" v-if="owner.OwnerSubscriptions.status_id==28" dense type="error">
                  حزمه الاشتراك الحاليه غير مفعله يرجئ تسديد الفاتوره عند اقرب منفذ كي كارد او الاتصال بأداره المنصه
                  للتفعيل
                </v-alert>
              </div>
            </v-flex>



            <v-flex xs12 md2 sm2 pt-3>
              <v-btn color=" green" dark class="add_dollasr_btn mb-2" @click="DollarsDialog=true" v-if="have_dollars">

                <v-icon size="15">fas fa-dollar-sign</v-icon>
                <span style="margin-right:5px;font-family:'cairo_Semi'">تغير سعر الدولار</span>


              </v-btn>

            </v-flex>



            <v-flex xs12 md2 sm2 pt-3>
              <v-btn color="primary" dark class="add_res_btn mb-2" to="/AddNewItem">
                <v-icon size="15">fas fa-plus</v-icon>
                <span style="margin-right:5px;font-family:'cairo_Semi'">اضافه خدمه جديده</span>


              </v-btn>

            </v-flex>







          </v-layout>


          <!-- dailog of add item -->

        </v-toolbar>
        <v-btn @click="GetDeletedItems();showDeleted=true" v-if="deleteItems" color="red" style="color:#fff" pb-4>
          الخدمات المحذوفه</v-btn>

      </template>


      <template v-slot:item.action="{ item }">
        <v-row wrap justify="center">


          <!-- <v-btn class="mx-2" small icon="" dark>
           -->
          <!-- <div v-if="item.offer!=''" style="width: 32px;height:32px">

              <div v-if="item.offer[0].is_valid==1 &&  item.offer[0].status_id==19">

                <v-img style="width:100%;height:100%" src="https://image.flaticon.com/icons/png/512/411/411705.png">
                </v-img>

              </div>

              <div v-else>

                <v-img style="width:100%;height:100%" src="https://image.flaticon.com/icons/png/512/411/411756.png">
                </v-img>

              </div>





            </div>


            <div v-else style="width: 32px;height:32px">






            </div>

          </v-btn> -->


          <!-- <v-btn class="mx-2" small icon="" :to="'/AddNewItem/'+item.id" dark>

            <v-icon color="warning" style="margin-left:5px">fas fa-pen-square</v-icon>

          </v-btn> -->

          <v-btn style="color:#fff" color="blue" class="mx-2" small :to="'/AddNewItem/'+item.id">
            <!-- <v-icon style="margin-left:5px">fas fa-pen-square fa-sm</v-icon> -->
            <i class="fas fa-pen-square fa-lg"></i>
            تعديل
          </v-btn>





          <v-btn style="color:#fff" color="blue" class="mx-2" small v-if="item.status.id==25"
            @click="return_delete_item(item.id)">
            استرجاع
          </v-btn>



          <!-- <v-btn class="mx-2" v-else small icon="" @click="delete_item(item.id)" dark>
            <v-icon color="red">fas fa-trash</v-icon>
          </v-btn> -->


          <!-- <v-btn style="color:#fff"  color="blue" class="mx-2" small  v-else  icon="fas fa-trash" @click="delete_item(item.id)">
                    <v-icon color="#fff">fas fa-trash</v-icon>
            حذف
          </v-btn> -->

          <v-btn style="color:#fff" color="blue" class="mx-2" small v-else @click="delete_item(item.id)">
            <i class="fas fa-trash fa-lg"></i>
            حذف
          </v-btn>








        </v-row>




      </template>

      <template v-slot:item.offers="{ item }">
        <v-row wrap justify="center">

          <div v-if="item.offer!=''">
            <v-btn class="mx-2" icon="" dark @click="edite_offer(item.offer[0].id,item.id,item.item_price)">
              <!-- <v-icon color="dark" style="margin-left:5px">fas fa-edit fa-sm</v-icon> -->
              <span style="font-weight:bold;color:blue;">تعديل</span>

            </v-btn>
          </div>
          <v-btn v-else class="mx-2" small icon="" @click="add_offer(item.id,item.price)">
            <!-- <v-icon color="dark" style="">fas fa-plus fa-sm</v-icon> -->
            <span style="font-weight:bold;color:blue;">اضافه عرض</span>
          </v-btn>
        </v-row>
      </template>






      <template v-slot:item.dayOpen="{ item }">
        <v-row wrap justify="center">

          <v-btn class="mx-2" icon="" :to="'EditeDayOpen/'+item.id" style="font-weight:bold">

            <!-- <v-icon color="dark" style="margin-left:5px;color:#000;font-size:10px;">fas fa-calendar-check</v-icon> -->
            <span style="font-weight:bold;color:blue;">تعديل</span>
          </v-btn>
        </v-row>
      </template>








      <template v-slot:item.ItemsReservationRequirements="{ item }">
        <v-row wrap justify="center">


          <v-btn v-if="item.ItemsReservationRequirements==0" class="mx-2" small icon="" style="font-weight:bold"
            @click="add_ItemsReservationRequirements(item.id)">

            <span style="font-weight:bold;color:blue;">اضافه مرفق</span>
          </v-btn>

          <v-btn v-else class="mx-2" small icon=""
            @click="edite_ItemsReservationRequirements(item.ItemsReservationRequirements,item.id)"
            style="font-weight:bold">

            <span style="font-weight:bold;color:blue;">تعديل</span>
          </v-btn>

        </v-row>

      </template>



      <template v-slot:item.ItemFeatures="{ item }">


        <v-row justify="center">
          <div v-if="item.ItemFeatures.length>0">
            <v-btn class="mx-2" small icon="" @click="ItemFeaturesDialogFun(item)" style="font-weight:bold">

              <span style="font-weight:bold;color:blue;">تعديل</span>
            </v-btn>

       

          </div>


          <div v-else-if="item.ItemFeatures.length==0">
            <v-btn class="mx-2" small icon="" style="font-weight:bold" @click="ItemFeaturesDialogFun(item)">

              <span style="font-weight:bold;color:blue;">اضافه مميزات </span>
            </v-btn>



          </div>
        </v-row>




      </template>


      <template v-slot:item.Image="{ item }">
        <v-row wrap justify="center">

          <v-btn class="mx-2" icon="" :to="'OwnerItemImage/'+item.id" style="font-weight:bold">
            <!-- <v-icon color="dark" style="margin-left:5px;color:#000;font-size:10px;">fas fa-calendar-check</v-icon> -->
            <span style="font-weight:bold;color:blue;">تعديل</span>
          </v-btn>
        </v-row>
      </template>







    </v-data-table>
    <v-row justify="center" style="width:100%">
      <v-pagination v-model="page" prev-icon="mdi-menu-right" next-icon="mdi-menu-left" circle="" :length="pageCount">
      </v-pagination>

    </v-row>
    <v-row>




      <v-dialog v-model="ItemsReservationRequirements_dialog" max-width="970" persistent>
        <v-card style="padding:20px">

          <v-card-title class="headline grey lighten-2" primary-title
            style="	font-family: 'droid_bold' !important;background-color: #eee!important;color:blue;font-size:14px">

            <span>مرفقات الحجز</span>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="ItemsReservationRequirements_dialog=false,ItemsReservationRequirements_info=''">
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
          </v-card-title>


          <div style="padding-top:9px;padding-bottom:9px">
            <v-btn dense small color="green" pt-4 pb-4 style="color:#fff"
              @click="req=true,req_action='add',req_name=''">
              اضافه مرفق جديد
            </v-btn>
          </div>

          <v-form ref="valid_ItemsReservationRequirements" v-model="valid_ItemsReservationRequirements">
            <div v-if="req">


              <v-layout row wrap pr-4>
                <v-flex xs6>
                  <v-text-field background-color="#eeeeee" height="40" style="" v-model="req_name" label="اسم المرفق"
                    :rules="ReqRules" required></v-text-field>

                </v-flex>
                <v-flex xs3 pr-3 pt-4>


                  <v-bt v-if="req_action=='edite'">
                    <v-btn @click="req_update()" color="green" pb-4 style="color:#fff">
                      تعديل
                    </v-btn>
                  </v-bt>

                  <v-btn v-else @click="req_save()" color="green" pb-4 style="color:#fff">
                    حفظ
                  </v-btn>


                  <v-btn pr-3 mr-4 color="red" @click="req=false" pb-4 style="color:#fff;margin-right:7px">
                    الغاء
                  </v-btn>

                </v-flex>
              </v-layout>

            </div>
          </v-form>

          <br>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>

                  <th class="text-right">
                    #
                  </th>
                  <th class="text-right">
                    اسم المرفق
                  </th>
                  <th class="text-right">

                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in ItemsReservationRequirements_info" :key="item">
                  <td>{{ item.id }}</td>
                  <td>{{ item.requirement_name }}</td>
                  <td>

                    <v-btn class="mx-2" small icon="" dark
                      @click="req=true,req_name=item.requirement_name,req_action='edite',req_id=item.id,editereq(item)">
                      <v-icon color="green" small class="mr-2">
                        edit
                      </v-icon>

                    </v-btn>


                    <v-btn @click="req_delete(item)" class="mx-2" small icon="" dark>
                      <v-icon color="red">fas fa-trash</v-icon>
                    </v-btn>





                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>


        </v-card>
      </v-dialog>

      <v-dialog v-model="offer_dialog" max-width="970" persistent>
        <v-form ref="form" v-model="valid">
          <v-card pa-4>

            <v-card-title class="headline grey lighten-2" primary-title
              style="	font-family: 'droid_bold' !important;    background-color: #435c6f!important;color:#fff">
              اضافه عرض
            </v-card-title>


            <v-container>
              <v-flex pa-3 xs12>
                <v-text-field background-color="#eeeeee" height="40" style="" label="اسم العرض"
                  v-model="offer_info.offer_name" :rules="nameRules" required></v-text-field>
              </v-flex>

              <v-layout row wrap>

                <v-flex xs4 pa-3>
                  <v-text-field label="نسبه الخصم" background-color="#eeeeee" height="40" @input="price_percentage"
                    @change="price_percentage" v-model="offer_info.discount_percentage" append-icon="fas fa-percent"
                    filled>
                  </v-text-field>
                </v-flex>



                <v-flex pa-3 xs4>

                  <v-text-field background-color="#eeeeee" height="40" label="السعر السابق"
                    v-model="offer_info.old_price" filled disabled append-icon="fas fa-dollar-sign" :rules="nameRules"
                    required>
                  </v-text-field>
                </v-flex>





                <v-flex pa-3 xs4>

                  <v-text-field background-color="#eeeeee" height="40" value label="السعر الجديد"
                    v-model="offer_info.offer_price" filled disabled append-icon="fas fa-dollar-sign">
                  </v-text-field>
                </v-flex>



              </v-layout>

              <v-layout row wrap>


                <v-flex xs6 pr-3 pl-4>
                  <v-menu v-model="menu_date_from" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="offer_info.offer_from_date" label="تاريخ بدا العرض" append-icon="event"
                        background-color="#eeeeee" height="40" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker locale="ar-iq" :min="date_today()" v-model="offer_info.offer_from_date"
                      @input="menu_date_from = false"></v-date-picker>
                  </v-menu>
                  <div class="" style="color:red" v-if="offer_info.is_valid==0">
                    العرض منتهي الصلاحيه

                  </div>

                </v-flex>


                <v-flex xs6 pr-3 pl-4>
                  <v-menu v-model="menu_date_to" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="offer_info.offer_to_date" label="تاريخ انتهاء العرض" append-icon="event"
                        background-color="#eeeeee" height="40" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :min="date_today()" v-model="offer_info.offer_to_date" @input="menu_date_to = false">
                    </v-date-picker>
                  </v-menu>

                </v-flex>
              </v-layout>


              

              <br>
              <br>

              <v-layout>
                <v-flex xs12>
                  <v-textarea filled name="input-7-4" v-model="offer_info.offer_description" label="وصف العرض"
                    height="200">
                  </v-textarea>

                </v-flex>
              </v-layout>


              <v-container fluid>


                <p>
                  حاله العرض :
                  <span v-if="offer_info.status_id==19">
                    مفعل
                  </span>

                  <span v-else>
                    ملغي
                  </span>

                </p>

                <v-radio-group v-model="offer_info.status_id" :mandatory="false">
                  <v-radio label="تفعيل" value="19"></v-radio>
                  <v-radio label="ايقاف" value="20"></v-radio>
                </v-radio-group>


              </v-container>

              <br>
              <br>


              <v-layout row wrap pr-5>
                <v-btn @click="submit_offer(item_id_offer)" color="success" style="margin-left:10px" width="150px">
                  <span v-show="!loading">حفظ</span>
                  <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>
                </v-btn>
                <v-btn @click="close_offer()" color="red" dark="" width="150px"> خروج</v-btn>
              </v-layout>



            </v-container>


          </v-card>
        </v-form>
      </v-dialog>




      <v-dialog v-model="dialog" max-width="1150" max-height="500" v-if="dialog" persistent>
        <itemDialog @changeStatus="dialog=false" :item_ID="itemId">
        </itemDialog>

      </v-dialog>


      <v-dialog v-model="DollarsDialog" max-width="600" max-height="400" persistent>

        <v-card color="#fff" height="auto" width="auto">
          <v-form ref="valid_DollarPrice" v-model="valid_DollarPrice">

            <v-layout row wrap pb-5 pr-4 pl-2>
              <v-flex xs3 md2 sm2 pt-5 style="font-size: 14px;
    margin-top: 26px;
    margin-right: 0px;
    padding-right: 6px;"><span>١ دولار امريكي = </span></v-flex>

              <v-flex xs7 md8 sm8 pt-5 pb-5 pr-4 pl-4 v-if="editedDollars.dollar_price">
                <v-lable>سعر صرف الدولار</v-lable>
                <v-text-field filled v-model="editedDollars.dollar_price" height="50" style=""
                  placeholder=" سعر صرف الدولار  " required :rules="dollar_priceRul"></v-text-field>
              </v-flex>

              <v-flex xs2 md2 sm2 style="    margin-top: 61px;"><span style="font-size:15px;padding-top:6px">دينار
                  عراقي</span></v-flex>
            </v-layout>

            <v-layout row wrap pt-5 pb-5 pr-4 pl-2>

              <v-flex xs3 pr-4>
                <v-btn style="width:100%" @click="ChangeDollars()" color="success">تغير</v-btn>
              </v-flex>

              <v-flex xs3 pr-2 pt-1>
                <v-btn style="width:100%" @click="DollarsDialog=false" color="error">خروج</v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>

      </v-dialog>







      <ItemFeatures v-if="ItemFeaturesDialog" :item_id="ItemFeatures_id" :ItemFeatures="ItemFeatures"></ItemFeatures>





    </v-row>
  </v-layout>
</template>


<script>
  const axios = require('axios');
  import moment from 'moment/src/moment'
  // import moment from 'moment';
  import {
    EventBus
  } from './event-bus.js';

  ///import Advertising from './sub_components/Advertising_dialog.vue'
  import ItemFeatures from './sub_components/ItemFeatures.vue'



  export default {
    data: () => ({
      dialog: false,
      item_status: '',

      have_dollars: false,

      editedDollars: {

        dollar_price: null



      },
      DollarsDialog: false,
      req_action: '',
      deleteItems: false,
      owner: {},
      showDeleted: false,
      ItemFeaturesDialog: false,
      switch1: true,
      valid_DollarPrice: false,
      req_id: '',
      req: false,
      ItemsReservationRequirements_dialog: false,
      switch2: false,
      item_id_offer: '',
      radios: 'radio-1',
      submit_name: '',
      item_new_price: '',
      menu_date_from: false,
      menu_date_to: false,
      editdailog: false,
      valid_ItemsReservationRequirements: false,
      user_info: {},
      itemId: '',
      itemId2: '',

      dollar_priceRul: [
        v => !!v || 'هذا الحقل مطلوب  ',
        v => !isNaN(v) || 'يجب ادخال رقم ',
        (v) => /[+-]?([0-9]*[.])?[0-9]+/.test(v) || 'يجب ادخال رقم ',
      ],

      req_name: '',
      token: '',
      map_dialog: false,
      modal2: false,
      offer_info: {


      },
      error: '',
      msg_error_txt: 'هذا اليوم مكرر ',


      headers: [{
          text: '#',
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: 'اسم الخدمه',
          align: 'center',
          sortable: false,
          value: 'item_name',
        },
        {
          text: 'سعر الخدمه',
          value: 'price.price_value',
          align: 'center',
        },
        {
          text: 'القسم',
          value: 'sub_category.category.name',
          sortable: false,
          align: 'center',
        },
        // {
        //   text: 'تاديخ الاضافة',
        //   value: 'created_at',
        //   sortable: false,
        //   align: 'center',
        // },
        {
          text: 'ميزات اضافيه',
          value: 'ItemFeatures',
          sortable: false,
          align: 'center',
        },

        {
          text: 'مرفقات الحجز ',
          value: 'ItemsReservationRequirements',
          sortable: false,
          align: 'center',
        },



        {
          text: ' اوقات العمل ',
          value: 'dayOpen',
          sortable: false,
          align: 'center',
        },

        {
          text: 'العروض',
          value: 'offers',
          sortable: false,
          align: 'center',
        },
        {
          text: 'صور المركز',
          value: 'Image',
          sortable: false,
          align: 'center',
        },


        {
          text: ' ',
          value: 'action',
          align: 'center',
        },

      ],
      page: 1,
      pageCount: 0,
      items: [],
      editedIndex: -1,
      ItemsReservationRequirements_info: [],
      item_req_id: '',

      valid: false,
      deduction: '',
      offer_info_id: '',
      Advertising_info: {},

      img_cliced: -1,
      day_to_open: [],

      nameRules: [
        v => !!v || 'هذا الحقل مطلوب  ',

      ],

      ReqRules: [
        v => !!v || 'هذا الحقل مطلوب  ',

      ],
      numberRul: [
        v => !!v || 'هذا الحقل مطلوب  ',

        v => !isNaN(v) || 'يجب ادخال رقم ',
      ],
      numberRul1: [

        v => !isNaN(v) || 'يجب ادخال رقم ',
      ],
      descriptRules: [
        v => !!v || 'هذا الحقل مطلوب  ',
        v => v.length >= 20 || 'يجب ان يكون الوصف اكثر من ٢٠ حرف ',
      ],
      loading: false,

      request: 0,
      current_page: 1,
      last_page: 0,
      offer_dialog: false,
      prov_id: '',
      itemFeaturesDialog: false,

      //msg_error:false,
      reserv_type_id: -1,
      offer_url: '',
      reservation_duration: '',
      editedIndexreq: -1,
      reservation_duration_foroneday: '',
      ItemFeatures_id: '',
      ItemFeatures: [],
    }),



    methods: {
      ItemFeaturesDialogFun(item) {
        console.log(item);
        this.ItemFeatures_id = item.id;

        this.ItemFeaturesDialog = true;
        this.ItemFeatures = item.ItemFeatures;


      },
      ChangeDollars() {

        if (this.$refs.valid_DollarPrice.validate()) {


          const Swal = require('sweetalert2')


          Swal.fire({
            title: "هل انت متاكد من تغير سعر تصريف الادولار ؟",

            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم',
            cancelButtonText: 'خروج'

          }).then((result) => {
            if (result.value) {
              var data = {
                dollar_price: this.editedDollars.dollar_price

              };

              this.$http({
                method: 'post',
                url: "v2/currency_transfers/owner/update",
                data: data,
                headers: {

                }

              }).then(response => {
                response
                this.DollarsDialog = false;

                this.$swal('تم', "تم تغير سعر صرف العمله ", 'success')



              }).catch(error => {
                error


              }).finally(d => {
                d
              });



            }
          })



        }

      },
      editereq(item) {
        this.editedIndexreq = this.ItemsReservationRequirements_info.indexOf(item);
      },

      req_delete(req) {

        this.editedIndexreq = this.ItemsReservationRequirements_info.indexOf(req);
        const Swal = require('sweetalert2')
        Swal.fire({
          title: "هل انت متاكد من حذف المرفق",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'نعم ',
          cancelButtonText: 'خروج'

        }).then(() => {

          if (req.id == undefined) {
            // const index = this.editedItem.ItemFeatures.indexOf(item)
            this.ItemsReservationRequirements_info.splice(this.editedIndexreq, 1)
            this.req = false,
              this.getitems();

            this.$swal('تم', "تم حذف المرفق", 'success')

          } else {


            axios({
                method: 'delete',
                url: '/v2/ItemsReservationRequirements/owner/delete/' + req.id,
                headers: {}
              })
              .then(response => {
                this.req = false,
                  this.ItemsReservationRequirements_info.splice(this.editedIndexreq, 1)
                this.getitems();
                this.$swal('تم', "تم حذف المرفق", 'success')
                this.$refs.valid_ItemsReservationRequirements.reset();
                if (response.data.success == true) {
                  this.$refs.form.reset()
                  this.dialog = false;
                }

              })
              .catch(error => {
                error


              }).finally(d => {
                d
              })
          }

        });




      },

      req_update() {

        if (this.$refs.valid_ItemsReservationRequirements.validate()) {

          var data = {
            // item_id: this.item_req_id,
            requirement_name: this.req_name
          };

          if (this.req_id == undefined) {
            // const index = this.editedItem.ItemFeatures.indexOf(item)
            this.ItemsReservationRequirements_info[this.editedIndexreq].requirement_name = this.req_name;
            this.req = false,
              this.getitems();

            this.$swal('   تم', "       تم   تعديل المرفق ", 'success')

          } else {
            this.ItemsReservationRequirements_info[this.editedIndexreq].requirement_name = this.req_name;

            axios({
                method: 'post',
                url: '/v2/ItemsReservationRequirements/owner/update/' + this.req_id,
                data: data,
                headers: {

                }

              })
              .then(response => {
                this.req = false,
                  this.getitems();

                this.$swal('   تم', "       تم   تعديل المرفق ", 'success')

                this.$refs.valid_ItemsReservationRequirements.reset();
                if (response.data.success == true) {
                  this.$refs.form.reset()
                  this.dialog = false;
                }

              })
              .catch(error => {
                error


              }).finally(d => {
                d
              });
          }
        }



      },
      req_save() {

        if (this.$refs.valid_ItemsReservationRequirements.validate()) {

          var data = {
            item_id: this.item_req_id,
            requirement_name: this.req_name

          };
          this.ItemsReservationRequirements_info.push(data);



          // if (this.submit_name == 'edite') {


          //   this.offer_url = '/v2/Offers/update/' + this.offer_info_id;


          // } else if (this.submit_name == 'add') {
          //   this.offer_url = '/v2/Offers/store';

          // }




          // this.loading = true;
          axios({
              method: 'post',
              url: '/v2/ItemsReservationRequirements/owner/store',
              data: data,
              headers: {

              }

            })
            .then(response => {
              this.req = false,
                this.$swal('تم', " تم اضافه مرفق", 'success')
              this.getitems();
              this.ItemsReservationRequirements_dialog = false,
                this.ItemsReservationRequirements_dialog = true,
                // this.offer_dialog = false;
                // this.offer_info = {};
                // this.offer_info.offer_price = '';
                // this.offer_info.old_price = '';
                // this.offer_info.id = '';
                // this.offer_info_id = '';
                // this.offer_info.discount_percentage = '';


                // this.getitems();
                this.$refs.valid_ItemsReservationRequirements.reset();

              if (response.data.success == true) {
                this.$refs.form.reset()
                this.dialog = false;


              }
              //



            })
            .catch(error => {
              error,
              this.err_show = true;
              //this.$swal('خطاء', "     لم يتم اضافة الحجز", 'error')

            }).finally(d => {
              d,
              this.offer_dialog = false;
              this.loading = false;
            });
        } else {

          this.err_show = true;




        }

      },

      GetOwnerInfo() {
        this.$http({
          method: 'get',
          url: 'owner/v2/OwnerInfo',
          headers: {

          }

        }).then(response => {

          this.owner = response.data.data
          if (this.owner.CurrencyTransfers !== null) {
         //   alert('sad');
            this.have_dollars = true;
            this.CurrencyTransfers();
            // this.item_status = this.items[0].owner.user.status.id;

          } else {
            //  this.item_status = 0;
          }
        }).catch(error => {
          error

        }).finally(s => {
          s

        })

      },

      date_today() {
        var x = new Date();
        return moment(String(x)).format('YYYY-MM-DD');

      },




      add_ItemsReservationRequirements(item_id) {
        item_id
        this.item_req_id = item_id,
          this.ItemsReservationRequirements_dialog = true;

      },

      edite_ItemsReservationRequirements(ItemsReservationRequirements_info, item_id) {

        this.item_req_id = item_id,

          this.ItemsReservationRequirements_info = ItemsReservationRequirements_info;
        this.ItemsReservationRequirements_dialog = true;

      },


      edit_item(item_id) {

        EventBus.$emit('edit_item', item_id);
        this.dialog = true;



      },

      getOfferById(id, old_price) {


        old_price
        var url = "/v2/Offers/show/" + id;
        this.$http({
          method: 'get',
          url: url,
        }).then(response => {
          this.offer_info = response.data.data;
          this.offer_info_id = response.data.data.id;
          this.offer_info.old_price = this.offer_info.item.price.price_value;
          //alert(this.offer_info.item.price.price_value)

        }).catch(error => {
          error
          // this.$swal('خطاء', "خطاء بالاتصال", 'error')
        }).finally(d => {
          d


        });


      },

      close_offer() {
        this.offer_info.id = '';
        this.offer_info_id = '';
        this.offer_dialog = false;
        this.offer_info.offer_price = '';
        this.offer_info.old_price = '';
        this.offer_info.discount_percentage = '';


      },

      submit_offer(item_id) {



        if (this.$refs.form.validate()) {



          var data = {

            item_id: item_id,
            offer_name: this.offer_info.offer_name,
            discount_percentage: this.offer_info.discount_percentage,
            offer_description: this.offer_info.offer_description,
            offer_from_date: this.offer_info.offer_from_date,
            offer_to_date: this.offer_info.offer_to_date,
            old_price: this.offer_info.old_price,
            status_id: this.offer_info.status_id,

          };


          if (this.submit_name == 'edite') {


            this.offer_url = '/v2/Offers/update/' + this.offer_info_id;


          } else if (this.submit_name == 'add') {
            this.offer_url = '/v2/Offers/store';

          }




          this.loading = true;
          axios({
              method: 'post',
              url: this.offer_url,
              data: data,
              headers: {

              }

            })
            .then(response => {
              this.$swal('تم', "    تم اضافة العرض", 'success')
              this.offer_dialog = false;
              this.offer_info = {};
              this.offer_info.offer_price = '';
              this.offer_info.old_price = '';
              this.offer_info.id = '';
              this.offer_info_id = '';
              this.offer_info.discount_percentage = '';


              this.getitems();
              this.$refs.form.reset();

              if (response.data.success == true) {
                this.$refs.form.reset()
                this.dialog = false;


              }
              //



            })
            .catch(error => {
              error,
              this.err_show = true;
              //this.$swal('خطاء', "     لم يتم اضافة الحجز", 'error')

            }).finally(d => {
              d,
              this.offer_dialog = false;
              this.loading = false;
            });
        } else {

          this.err_show = true;




        }

      },


      return_delete_item(id) {


        this.user_info = this.$cookies.get('admin_info');
        const Swal = require('sweetalert2')


        Swal.fire({
          title: "هل انت متاكد من استرجاع الحجز ؟",

          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'نعم',
          cancelButtonText: 'خروج'

        }).then((result) => {
          if (result.value) {
            var url = "/v2/items/owner/return_delete/" + id;
            axios({
              method: 'get',
              url: url,


            }).then(response => {
              response
              this.GetDeletedItems();


            }).catch(error => {
              error
              // this.$swal('خطاء', "خطاء بالاتصال", 'error')
            }).finally(d => {
              d,
              Swal.fire(
                'تم استرجاع الحجز',
                '',
                'success'
              )
              this.getitems();
            });



          }
        })



      },


      delete_item(id) {


        this.user_info = this.$cookies.get('admin_info');
        const Swal = require('sweetalert2')


        Swal.fire({
          title: "هل انت متاكد من الحذف ؟",

          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'نعم',
          cancelButtonText: 'خروج'

        }).then((result) => {
          if (result.value) {
            var url = "/v2/items/delete/" + id;
            axios({
              method: 'delete',
              url: url,
              headers: {}

            }).then(response => {
              response
              this.GetDeletedItems();


            }).catch(error => {
              error
              // this.$swal('خطاء', "خطاء بالاتصال", 'error')
            }).finally(d => {
              d,
              Swal.fire(
                'تم الحذف بنجاح',
                '',
                'success'
              )
              this.getitems();
            });



          }
        })



      },


      price_percentage(v) {

        this.offer_info.offer_price = this.offer_info.old_price;
        this.offer_info.offer_price = this.offer_info.old_price - (this.offer_info.old_price * (v / 100));
        this.offer_info.discount_percentage = v;



      },


      select_reser_type(id) {

        this.reserv_type_id = id;
        if (id == 1) {
          this.book_dur_status = true;
          this.book_num_status = false;
        } else if (id == 2) {
          this.book_dur_status = false;
          this.book_num_status = true;
        } else {
          this.book_dur_status = false;
          this.book_num_status = false;
        }
      },

      allowedHours(v) {
        v
        return false;
      },


      edite_offer(id, item_id, old_price) {

        this.submit_name = 'edite';


        this.item_id_offer = item_id;

        this.offer_dialog = true;
        this.getOfferById(id, old_price);



      },

      add_offer(item_id, item_price) {
        item_price
        //this.editedDollars.dollar_price='';
        this.offer_info = {};
        this.submit_name = 'add';
        this.item_id_offer = item_id;
        this.offer_dialog = true;
        //    alert( item_price.price_value);
         this.offer_info.old_price = item_price.price_dinar;



      },

      GetDeletedItems() {

        this.loading = true
        this.$http({
          method: 'get',
          url: "/v2/items/owner/GetDeletedItems?page=" + this.current_page,


        }).then(response => {
          // this.getitems();
          if (response.data.data.length > 0) {
            this.deleteItems = true;
          } else {
            this.deleteItems = false;

          }
          if (this.showDeleted) {
            this.items = response.data.data;

            this.item_status = this.items[0].owner.status_id;
            // alert(this.item_status);

            //alert(items[0].owner.user.status.id);
            this.last_page = response.data.meta.last_page;
            this.pageCount = response.data.meta.last_page;
            // if (this.items[0]) {
            //   this.$cookies.set('last_owner_item', this.items[0].id);

            // } else {
            //   this.$cookies.set('last_owner_item', 0)
            // }


          }

        }).catch(error => {
          if (error.response.status == 402) {
            this.$swal(" انتهت الجلسة", "يجب تسجيل الدخول", 'error')
            this.logout();

          } else {
            // this.$swal('خطاء', "خطاء بالاتصال", 'error')
          }

        }).finally(d => {
          d,
          this.loading = false;
        });

      },

      CurrencyTransfers() {
        this.$http({
          method: 'get',
          url: "v2/currency_transfers/owner/get",
          headers: {

          }

        }).then(response => {
          this.editedDollars = response.data.Data;



        }).catch(error => {
          error


        }).finally(d => {
          d
        });
      },
      getitems() {


        this.loading = true
        this.$http({
          method: 'get',
          url: "/v2/items/owner/get?page=" + this.current_page,


        }).then(response => {

          this.items = response.data.data;
          if (this.items[0]) {

            this.$cookies.set('last_owner_item', this.items[0].id);

          } else {
            this.$cookies.set('last_owner_item', 0)
          }

          this.item_status = this.items[0].owner.status_id;
     

          if (this.items.length > 0) {

       

   
            this.last_page = response.data.meta.last_page;
            this.pageCount = response.data.meta.last_page;




          }

        }).catch(error => {
          error


        }).finally(d => {
          d,
          this.loading = false;
        });

      },




      getMoreitems() {

        // if (this.current_page <= this.last_page) {
        //   this.current_page = this.page;
        //   this.getitems();
        // }

      },



      showEditdailog(id) {

        this.dialog = true;
        this.itemId = id;


      },




      close() {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save() {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },

    },

    watch: {
      selected: 'search by sub_cat_id',
    },

    computed: {
      selected: function () {
        return this.getMoreitems();
      }
    },
    created() {


      EventBus.$on('changeStatus', (from) => {
        from,
        this.dialog = false;
        this.getitems();

      });
      EventBus.$on('ItemFeature', (from) => {
        from,
        this.ItemFeaturesDialog = false;
        this.getitems();

      });

    },
    mounted() {
      this.user_info = this.$cookies.get('admin_info');
      this.getitems();
      this.GetOwnerInfo();
      this.GetDeletedItems();


    },
    components: {
      //Advertising,

      ItemFeatures

    }
  }
</script>